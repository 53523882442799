<template>
  <div class="text-center">
    <v-dialog
      max-width="600px"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
      v-model="dialog"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          :x-small="small"
          v-text="text"
          :color="color"
          v-bind="attrs"
          v-on="on"
          @click="dialog = true"
        >
        </v-btn>
      </template>

      <v-card>
        <v-toolbar dark color="primary">
          <v-toolbar-title>Novo compromisso</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon dark @click="dialog = false">
            <v-icon>fas fa-times</v-icon>
          </v-btn>
        </v-toolbar>

        <v-card-text>
          <FormAgenda :typeEvento="typeEvento" :processoDoc="processoDoc" />
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import FormAgenda from "./FormAgenda.vue";
export default {
  props: {
    typeEvento: {
      type: String,
      required: true,
      default: "compromisso",
    },
    color: {
      type: String,
      default: "primary",
    },

    text: {
      type: String,
      default: "Novo compromisso",
    },

    small: {
      type: Boolean,
      default: false,
    },

    "x-small": {
      type: Boolean,
      default: false,
    },
    processoDoc: String,
  },
  components: { FormAgenda },
  data() {
    return {
      dialog: false,
    };
  },
};
</script>

<style></style>
