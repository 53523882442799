<template>
  <validation-observer ref="observer" v-slot="{ invalid }">
    <form @submit.prevent="submit">
      <v-container>
        <v-row class="text-center">
          <v-col cols="12">
            <h3>Dados de Horario</h3>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="6">
            <validation-provider
              v-slot="{ errors }"
              name="Data Inicial"
              rules="required"
            >
              <v-text-field
                v-model="compromisso.date"
                :error-messages="errors"
                label="Data Inicial*"
                required
                type="date"
              ></v-text-field>
            </validation-provider>
          </v-col>

          <v-col cols="12" md="3">
            <validation-provider
              v-slot="{ errors }"
              name="Hora Inicial"
              rules="required"
            >
              <v-text-field
                v-model="compromisso.horaInicio"
                :error-messages="errors"
                label="Hora Inicial*"
                required
                type="time"
              ></v-text-field>
            </validation-provider>
          </v-col>

          <v-col cols="12" md="3">
            <validation-provider
              v-slot="{ errors }"
              name="Hora Final"
              rules="required"
            >
              <v-text-field
                v-model="compromisso.horaFinal"
                :error-messages="errors"
                label="Hora Final*"
                required
                type="time"
              ></v-text-field>
            </validation-provider>
          </v-col>

          <v-col cols="12" md="6">
            <validation-provider
              v-slot="{ errors }"
              name="Recorrencia *"
              rules="required"
            >
              <v-select
                :items="recorrencias"
                v-model="compromisso.recurrence"
                label="Recorrencia"
                :error-messages="errors"
              ></v-select>
            </validation-provider>
          </v-col>

          <v-col cols="12" md="6" v-show="compromisso.recurrence != 'nenhum'">
            <validation-provider v-slot="{ errors }" name="Data Limite">
              <v-text-field
                v-model="compromisso.deadline"
                :error-messages="errors"
                label="Data Limite"
                type="date"
              ></v-text-field>
            </validation-provider>
          </v-col>
        </v-row>

        <v-row class="text-center">
          <v-col cols="12">
            <h3>Informações</h3>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12" md="6">
            <validation-provider
              v-slot="{ errors }"
              name="Tipo de compromisso"
              rules="required"
            >
              <v-select
                :items="tipos"
                item-text="name"
                item-value="id"
                v-model="compromisso.tipo"
                label="Tipo de compromisso"
                :error-messages="errors"
              ></v-select>
            </validation-provider>
          </v-col>
          <v-col cols="12" md="6">
            <validation-provider
              v-slot="{ errors }"
              name="Status"
              rules="required"
            >
              <v-select
                :items="status"
                item-value="id"
                item-text="name"
                v-model="compromisso.status"
                label="Status do compromisso"
                :error-messages="errors"
                multiple
              ></v-select>
            </validation-provider>
          </v-col>

          <v-col cols="12" md="4">
            <validation-provider
              v-slot="{ errors }"
              name="Descrição"
              rules="required"
            >
              <v-textarea
                label="Descrição"
                v-model="compromisso.description"
                required
                :error-messages="errors"
                :counter="255"
              ></v-textarea>
            </validation-provider>
          </v-col>

          <v-col cols="12" md="4">
            <validation-provider v-slot="{ errors }" name="Complemento">
              <v-textarea
                label="Complemento"
                v-model="compromisso.complement"
                :error-messages="errors"
                :counter="255"
              ></v-textarea>
            </validation-provider>
          </v-col>

          <v-col cols="12" md="4">
            <validation-provider v-slot="{ errors }" name="Observação">
              <v-textarea
                label="Observação"
                v-model="compromisso.observation"
                :error-messages="errors"
                :counter="255"
              ></v-textarea>
            </validation-provider>
          </v-col>
        </v-row>

        <v-row class="text-center">
          <v-col cols="12">
            <h3>Partes do Compromisso</h3>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12" md="4">
            <validation-provider
              v-slot="{ errors }"
              name="Solicitantes"
              rules="required"
            >
              <v-select
                multiple
                :items="users"
                v-model="compromisso.solicitantes"
                :item-text="'name'"
                item-value="id"
                label="Solicitantes"
                :error-messages="errors"
                :loading="loadingUsers"
              ></v-select>
            </validation-provider>
          </v-col>

          <v-col cols="12" md="4">
            <validation-provider
              v-slot="{ errors }"
              name="Responsavel"
              rules="required"
            >
              <v-select
                :items="usersResponsible"
                v-model="compromisso.responsible"
                item-text="name"
                item-value="id"
                label="Responsavel"
                :error-messages="errors"
                :loading="loadingUsers"
              ></v-select>
            </validation-provider>
          </v-col>

          <v-col cols="12" md="4">
            <validation-provider v-slot="{ errors }" name="Co-responsaveis">
              <v-select
                multiple
                :items="usersResponsible"
                v-model="compromisso.responsaveis"
                item-text="name"
                item-value="id"
                label="Co-responsaveis"
                :error-messages="errors"
                :loading="loadingUsers"
              ></v-select>
            </validation-provider>
          </v-col>
        </v-row>

        <v-row class="text-center">
          <v-col cols="12">
            <h3>Arquivos</h3>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12">
            <validation-provider v-slot="{ errors }" name="Co-responsaveis">
              <v-file-input
                v-model="compromisso.files"
                multiple
                truncate-length="15"
                :error-messages="errors"
              ></v-file-input>
            </validation-provider>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12">
            <v-btn
              class="mr-4"
              color="success"
              type="submit"
              :disabled="invalid"
            >
              Salvar
            </v-btn>
            <v-btn color="warning" @click="clear"> Limpar </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </form>
  </validation-observer>
</template>

<script>
import { required, max } from "vee-validate/dist/rules";
import axiosFormData from "@/plugins/axiosFormData.js";
// Activate the Arabic locale.

import {
  extend,
  ValidationObserver,
  ValidationProvider,
  setInteractionMode,
} from "vee-validate";

import instanceAxios from "../../plugins/axios";
import ToastService from "../../services/ToastService";

setInteractionMode("eager");

extend("required", {
  ...required,
  message: "{_field_} não pode ser nulo",
});

extend("max", {
  ...max,
  message: "{_field_} may not be greater than {length} characters",
});

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
  },

  props: {
    typeEvento: {
      type: String,
      required: true,
      default: "compromisso",
    },
    processoDoc: String,
  },

  data: () => ({
    compromisso: {},
    users: [],
    loadingUsers: false,

    tipos: [],

    recorrencias: ["nenhum", "diario", "semanal", "mensal"],
    status: [
      { id: 1, name: "Iniciado" },
      { id: 2, name: "Realizado" },
      { id: 3, name: "Privado" },
      { id: 4, name: "Não editavel" },
      { id: 5, name: "Cancelado" },
      { id: 6, name: "Urgente" },
    ],
  }),

  computed: {
    usersResponsible: function () {
      return this.users.filter((el) => el.role.type == "responsible");
    },
  },

  mounted() {
    this.getUsers();
    this.loadTipos();
  },

  methods: {
    async submit() {
      try {
        this.$refs.observer.validate();

        let data = new FormData();
        let form = this.compromisso;

        data.append("start_date", `${form.date} ${form.horaInicio}`);
        data.append("end_date", `${form.date} ${form.horaFinal}`);
        data.append("recurrence", form.recurrence);
        data.append("schedule_type_id", 1);
        data.append("description", form.description);
        data.append("observation", form.observation ?? "");
        data.append("complement", form.complement ?? "");
        data.append("responsible", form.responsible);
        data.append("tipo", this.typeEvento);
        data.append("lawsuit_id", this.processoDoc ?? "");
        if (form.deadline) {
          data.append("deadline", form.deadline ?? "");
        }

        if (form.responsaveis) {
          form.responsaveis.forEach((element) =>
            data.append("responsibles[]", element)
          );
        }

        if (form.solicitantes) {
          form.solicitantes.forEach((element) =>
            data.append("requesters[]", element)
          );
        }

        if (form.status) {
          form.status.forEach((element) => data.append("status[]", element));
        }

        if (form.files) {
          form.files.forEach((element) => data.append("files[]", element));
        }

        await axiosFormData.post("schedules", data);

        ToastService("Criado com sucesso", "success");

        this.compromisso = {};
        this.$refs.observer.reset();

        this.$root.$emit("updateAgenda");
      } catch (error) {
        console.log(error);
        ToastService("Não foi possivel cadastrar", "error");
      }
    },
    clear() {
      this.compromisso = {};
      this.$refs.observer.reset();
    },

    async getUsers() {
      try {
        this.loadingUsers = true;

        const { data } = await instanceAxios.get("users");

        this.users = data;

        this.loadingUsers = false;
      } catch (error) {
        ToastService("Erro ao tentar buscar os dados");
      }
    },

    async loadTipos() {
      const { data } = await instanceAxios.get("schedules/types");

      this.tipos = data;
    },
  },
};
</script>

<style></style>
